import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'

import ListIcon from '@material-ui/icons/FormatListBulleted'
import GridIcon from '@material-ui/icons/Apps'
import TableChartIcon from '@material-ui/icons/TableChart'
import { useTheme } from '@material-ui/styles'

import Button from '../inputs/Button'

const ViewMode = ({ toggleView, activeView }) => {
  const theme = useTheme()

  return (
    <div className="mx-2">
      <Button
        id="proposal-cards"
        onClick={() => toggleView('grid')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'grid'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`
        }}
        size="xs"
      >
        <GridIcon />
      </Button>

      <Button
        id="list-view"
        onClick={() => toggleView('list')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'list'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`
        }}
        size="xs"
      >
        <ListIcon />
      </Button>

      <Button
        id="info-table"
        onClick={() => toggleView('table')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'table'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`
        }}
        size="xs"
      >
        <TableChartIcon />
      </Button>
    </div>
  )
}

ViewMode.propTypes = {
  toggleView: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  comments: state.firestore.data.comments
})

export default compose(
  firestoreConnect([{ collection: 'comments' }]),
  connect(mapStateToProps)
)(ViewMode)
