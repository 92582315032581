import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  card: {
    border: '1px solid transparent',
    width: '16rem',
    // height: '2.125rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground
  },
  cardBorder: {
    width: '16rem',
    // height: '1.875rem',
    border: '1px solid #f46b1e',
    boxShadow: 'none'
  },
  title: {
    marginBottom: 0,
    paddingTop: '0.3125rem',
    paddingBottom: 1,
    paddingLeft: '0.375rem'
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#ffa632',
    zIndex: 2,
    width: '0.625rem',
    height: '2.125rem'
  },
  truncate: {
    width: '170px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    fontWeight: 400,
    paddingRight: '10px'
  },
  actionArea: {
    // padding: '7px 10px',
  }
})

const XsProposalWithName = ({ classes, seen, proposal, proposalClass }) => (
  <Card className={classNames(classes.card, { [classes.cardBorder]: !seen })}>
    <CardActionArea
      classes={{
        root: classes.actionArea
      }}
    >
      <Box display="flex" alignItems="center" width="100%">
        <div
          style={{
            backgroundColor: proposalClass ? proposalClass.colour : '#e8e8e8',
            minWidth: '30px',
            margin: '-1px',
            padding: '15px 10px 10px',
            textAlign: 'center'
          }}
        >
          <h3
            className="mb-0"
            style={{ color: proposalClass ? '#fff' : '#2e2e2e' }}
          >
            {proposal.number}
          </h3>
        </div>
        <Box ml="10px">
          <h5 className={classes.truncate}>{proposal.name}</h5>
        </Box>
      </Box>
      {/* {proposalClass && (
        <div
          className={classes.classStyle}
          style={{backgroundColor: proposalClass.colour}}
        />
      )} */}
    </CardActionArea>
  </Card>
)

XsProposalWithName.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(XsProposalWithName)
